import { default as _404zbUFvgvMaKMeta } from "/home/ubuntu/product-module/pages/404.vue?macro=true";
import { default as account7s4z5kAaSHMeta } from "/home/ubuntu/product-module/pages/account.vue?macro=true";
import { default as bundlesCAVxa829WyMeta } from "/home/ubuntu/product-module/pages/bundles.vue?macro=true";
import { default as cartUm6tNkODx9Meta } from "/home/ubuntu/product-module/pages/cart.vue?macro=true";
import { default as indexnYiGYuW9zCMeta } from "/home/ubuntu/product-module/pages/checkout/index.vue?macro=true";
import { default as paymentifLbbEFXk4Meta } from "/home/ubuntu/product-module/pages/checkout/payment.vue?macro=true";
import { default as contactUsbJNyVB3SPZMeta } from "/home/ubuntu/product-module/pages/contactUs.vue?macro=true";
import { default as _91id_93b2OOx1n8C9Meta } from "/home/ubuntu/product-module/pages/custom/[id].vue?macro=true";
import { default as faqHAR9cdtftbMeta } from "/home/ubuntu/product-module/pages/faq.vue?macro=true";
import { default as indexmILF7rAwO1Meta } from "/home/ubuntu/product-module/pages/index.vue?macro=true";
import { default as loginpr1LJ979ShMeta } from "/home/ubuntu/product-module/pages/login.vue?macro=true";
import { default as order_45placedR8QH4aSYp8Meta } from "/home/ubuntu/product-module/pages/order-placed.vue?macro=true";
import { default as contentYr9QfOBJVPMeta } from "/home/ubuntu/product-module/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedo4Sp58sJg5Meta } from "/home/ubuntu/product-module/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionTHxB5LAUbVMeta } from "/home/ubuntu/product-module/pages/processing-transaction.vue?macro=true";
import { default as _91id_9356ID8kEoB6Meta } from "/home/ubuntu/product-module/pages/products/[id].vue?macro=true";
import { default as indexFBYrRglyE8Meta } from "/home/ubuntu/product-module/pages/products/index.vue?macro=true";
import { default as registerInwj21VxeTMeta } from "/home/ubuntu/product-module/pages/register.vue?macro=true";
import { default as resetJkmQ5n81H3Meta } from "/home/ubuntu/product-module/pages/reset.vue?macro=true";
import { default as staticfQ68ezZ6OyMeta } from "/home/ubuntu/product-module/pages/static.vue?macro=true";
import { default as verify845zbtBp5RMeta } from "/home/ubuntu/product-module/pages/verify.vue?macro=true";
import { default as wishlistuxMg77rxQBMeta } from "/home/ubuntu/product-module/pages/wishlist.vue?macro=true";
import { default as tenantLoginhDwV7zFGSTMeta } from "/home/ubuntu/product-module/components/tenantLogin.vue?macro=true";
export default [
  {
    name: _404zbUFvgvMaKMeta?.name ?? "404",
    path: _404zbUFvgvMaKMeta?.path ?? "/404",
    meta: _404zbUFvgvMaKMeta || {},
    alias: _404zbUFvgvMaKMeta?.alias || [],
    redirect: _404zbUFvgvMaKMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/404.vue").then(m => m.default || m)
  },
  {
    name: account7s4z5kAaSHMeta?.name ?? "account",
    path: account7s4z5kAaSHMeta?.path ?? "/account",
    meta: account7s4z5kAaSHMeta || {},
    alias: account7s4z5kAaSHMeta?.alias || [],
    redirect: account7s4z5kAaSHMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/account.vue").then(m => m.default || m)
  },
  {
    name: bundlesCAVxa829WyMeta?.name ?? "bundles",
    path: bundlesCAVxa829WyMeta?.path ?? "/bundles",
    meta: bundlesCAVxa829WyMeta || {},
    alias: bundlesCAVxa829WyMeta?.alias || [],
    redirect: bundlesCAVxa829WyMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/bundles.vue").then(m => m.default || m)
  },
  {
    name: cartUm6tNkODx9Meta?.name ?? "cart",
    path: cartUm6tNkODx9Meta?.path ?? "/cart",
    meta: cartUm6tNkODx9Meta || {},
    alias: cartUm6tNkODx9Meta?.alias || [],
    redirect: cartUm6tNkODx9Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexnYiGYuW9zCMeta?.name ?? "checkout",
    path: indexnYiGYuW9zCMeta?.path ?? "/checkout",
    meta: indexnYiGYuW9zCMeta || {},
    alias: indexnYiGYuW9zCMeta?.alias || [],
    redirect: indexnYiGYuW9zCMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: paymentifLbbEFXk4Meta?.name ?? "checkout-payment",
    path: paymentifLbbEFXk4Meta?.path ?? "/checkout/payment",
    meta: paymentifLbbEFXk4Meta || {},
    alias: paymentifLbbEFXk4Meta?.alias || [],
    redirect: paymentifLbbEFXk4Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: contactUsbJNyVB3SPZMeta?.name ?? "contactUs",
    path: contactUsbJNyVB3SPZMeta?.path ?? "/contactUs",
    meta: contactUsbJNyVB3SPZMeta || {},
    alias: contactUsbJNyVB3SPZMeta?.alias || [],
    redirect: contactUsbJNyVB3SPZMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93b2OOx1n8C9Meta?.name ?? "custom-id",
    path: _91id_93b2OOx1n8C9Meta?.path ?? "/custom/:id()",
    meta: _91id_93b2OOx1n8C9Meta || {},
    alias: _91id_93b2OOx1n8C9Meta?.alias || [],
    redirect: _91id_93b2OOx1n8C9Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: faqHAR9cdtftbMeta?.name ?? "faq",
    path: faqHAR9cdtftbMeta?.path ?? "/faq",
    meta: faqHAR9cdtftbMeta || {},
    alias: faqHAR9cdtftbMeta?.alias || [],
    redirect: faqHAR9cdtftbMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexmILF7rAwO1Meta?.name ?? "index",
    path: indexmILF7rAwO1Meta?.path ?? "/",
    meta: indexmILF7rAwO1Meta || {},
    alias: indexmILF7rAwO1Meta?.alias || [],
    redirect: indexmILF7rAwO1Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginpr1LJ979ShMeta?.name ?? "login",
    path: loginpr1LJ979ShMeta?.path ?? "/login",
    meta: loginpr1LJ979ShMeta || {},
    alias: loginpr1LJ979ShMeta?.alias || [],
    redirect: loginpr1LJ979ShMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45placedR8QH4aSYp8Meta?.name ?? "order-placed",
    path: order_45placedR8QH4aSYp8Meta?.path ?? "/order-placed",
    meta: order_45placedR8QH4aSYp8Meta || {},
    alias: order_45placedR8QH4aSYp8Meta?.alias || [],
    redirect: order_45placedR8QH4aSYp8Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/order-placed.vue").then(m => m.default || m)
  },
  {
    name: contentYr9QfOBJVPMeta?.name ?? "pages-key-content",
    path: contentYr9QfOBJVPMeta?.path ?? "/pages/:key()/content",
    meta: contentYr9QfOBJVPMeta || {},
    alias: contentYr9QfOBJVPMeta?.alias || [],
    redirect: contentYr9QfOBJVPMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/pages/[key]/content.vue").then(m => m.default || m)
  },
  {
    name: payment_45failedo4Sp58sJg5Meta?.name ?? "payment-failed",
    path: payment_45failedo4Sp58sJg5Meta?.path ?? "/payment-failed",
    meta: payment_45failedo4Sp58sJg5Meta || {},
    alias: payment_45failedo4Sp58sJg5Meta?.alias || [],
    redirect: payment_45failedo4Sp58sJg5Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: processing_45transactionTHxB5LAUbVMeta?.name ?? "processing-transaction",
    path: processing_45transactionTHxB5LAUbVMeta?.path ?? "/processing-transaction",
    meta: processing_45transactionTHxB5LAUbVMeta || {},
    alias: processing_45transactionTHxB5LAUbVMeta?.alias || [],
    redirect: processing_45transactionTHxB5LAUbVMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/processing-transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_9356ID8kEoB6Meta?.name ?? "products-id",
    path: _91id_9356ID8kEoB6Meta?.path ?? "/products/:id()",
    meta: _91id_9356ID8kEoB6Meta || {},
    alias: _91id_9356ID8kEoB6Meta?.alias || [],
    redirect: _91id_9356ID8kEoB6Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFBYrRglyE8Meta?.name ?? "products",
    path: indexFBYrRglyE8Meta?.path ?? "/products",
    meta: indexFBYrRglyE8Meta || {},
    alias: indexFBYrRglyE8Meta?.alias || [],
    redirect: indexFBYrRglyE8Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerInwj21VxeTMeta?.name ?? "register",
    path: registerInwj21VxeTMeta?.path ?? "/register",
    meta: registerInwj21VxeTMeta || {},
    alias: registerInwj21VxeTMeta?.alias || [],
    redirect: registerInwj21VxeTMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetJkmQ5n81H3Meta?.name ?? "reset",
    path: resetJkmQ5n81H3Meta?.path ?? "/reset",
    meta: resetJkmQ5n81H3Meta || {},
    alias: resetJkmQ5n81H3Meta?.alias || [],
    redirect: resetJkmQ5n81H3Meta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: staticfQ68ezZ6OyMeta?.name ?? "static",
    path: staticfQ68ezZ6OyMeta?.path ?? "/static",
    meta: staticfQ68ezZ6OyMeta || {},
    alias: staticfQ68ezZ6OyMeta?.alias || [],
    redirect: staticfQ68ezZ6OyMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/static.vue").then(m => m.default || m)
  },
  {
    name: verify845zbtBp5RMeta?.name ?? "verify",
    path: verify845zbtBp5RMeta?.path ?? "/verify",
    meta: verify845zbtBp5RMeta || {},
    alias: verify845zbtBp5RMeta?.alias || [],
    redirect: verify845zbtBp5RMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: wishlistuxMg77rxQBMeta?.name ?? "wishlist",
    path: wishlistuxMg77rxQBMeta?.path ?? "/wishlist",
    meta: wishlistuxMg77rxQBMeta || {},
    alias: wishlistuxMg77rxQBMeta?.alias || [],
    redirect: wishlistuxMg77rxQBMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: tenantLoginhDwV7zFGSTMeta?.name ?? "tenantLogin",
    path: tenantLoginhDwV7zFGSTMeta?.path ?? "/tenantLogin",
    meta: tenantLoginhDwV7zFGSTMeta || {},
    alias: tenantLoginhDwV7zFGSTMeta?.alias || [],
    redirect: tenantLoginhDwV7zFGSTMeta?.redirect,
    component: () => import("/home/ubuntu/product-module/components/tenantLogin.vue").then(m => m.default || m)
  }
]